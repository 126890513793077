var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('Header'),_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title']),expression:"['title']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'type',
                { rules: [{ required: true, message: '请选择！' }] },
              ]),expression:"[\n                'type',\n                { rules: [{ required: true, message: '请选择！' }] },\n              ]"}],staticStyle:{"width":"100%"}},_vm._l((_vm.typeList),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"内容","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'content',
                { rules: [{ required: true, message: '请输入！' }] },
              ]),expression:"[\n                'content',\n                { rules: [{ required: true, message: '请输入！' }] },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"图片","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('Upload',{attrs:{"list":_vm.images},on:{"change":(payload) => (_vm.images = payload)}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"是否公开","label-col":{ span: 3 },"wrapper-col":{ span: 19 }}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isPublic',
                {
                  valuePropName: 'checked',
                },
              ]),expression:"[\n                'isPublic',\n                {\n                  valuePropName: 'checked',\n                },\n              ]"}],attrs:{"checked-children":"是","un-checked-children":"否"}})],1)],1)],1),_c('div',{staticClass:"footer center"},[_c('a-space',[_c('a-button',{attrs:{"htmlType":"submit","type":"primary","loading":_vm.loading}},[_vm._v("发布")]),_c('a-button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }