<template>
  <div>
    <Pane />
    <a-card class="container">
      <Header />
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 14 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="标题">
              <a-input v-decorator="['title']" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="类型">
              <a-select
                style="width: 100%"
                v-decorator="[
                  'type',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="内容"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-textarea
                v-decorator="[
                  'content',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="图片"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <Upload
                :list="images"
                @change="(payload) => (images = payload)"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="是否公开"
              :label-col="{ span: 3 }"
              :wrapper-col="{ span: 19 }"
            >
              <a-switch
                v-decorator="[
                  'isPublic',
                  {
                    valuePropName: 'checked',
                  },
                ]"
                checked-children="是"
                un-checked-children="否"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="footer center">
          <a-space>
            <a-button htmlType="submit" type="primary" :loading="loading"
              >发布</a-button
            >
            <a-button @click="$router.go(-1)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>
  
<script>
import { add } from "./api";
import { mapGetters } from "vuex";
import Upload from "./components/upload.vue";
import Header from "./components/header.vue";
export default {
  components: {
    Upload,
    Header,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      loading: false,
      images: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("informType");
    },
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);

          this.loading = true;

          add({
            ...values,
            isPublic: !!values.isPublic,
            picture: this.images.map((item) => item.completePath).join("，"),
            title: values.title
              ? values.title
              : this.typeList.find((item) => item.value === values.type).name +
                values.content.substring(0, 7),
          })
            .then(() => {
              this.$router.go(-1);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.footer {
  margin-top: 80px;
  margin-bottom: 80px;
}
</style>

<style>
.ant-switch {
  border-radius: 2px !important;
}
.ant-switch::after {
  border-radius: 2px !important;
}
</style>